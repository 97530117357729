import { LOAD_PROJECTS } from '../actions';

function projectReducer(state = [], action) {
  switch (action.type) {
    case LOAD_PROJECTS:
      for (const project in action.projects || {}) {
        action.projects[project].id = project;
      }
      return Object.values(action.projects);
    default:
      return state;
  }
}

export default projectReducer;
