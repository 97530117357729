import axios from 'axios';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { loadProjects } from '../store';

export default function LoadingCard() {
  const [progress, setProgress] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const dispatch = useDispatch();

  const download = async () => {
    const response = await axios({
      url: '/data.json',
      onDownloadProgress(progressEvent) {
        setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      }
    });
 
    setProgress(100);
    dispatch(loadProjects(response.data));
    setTimeout(() => setOpacity(0), 1000);
  }

  useEffect(() => {
    download();
  }, []);

  return (
    <div style={{
      position: 'fixed',
      left: 16,
      bottom: 16,
      zIndex: 10000,
      transition: 'all 1s',
      visibility: opacity === 0 ? 'hidden' : 'visible',
      transform: `translateX(${opacity === 1 ? 0 : -500}px)`
    }}>
      <Card sx={{
        minWidth: 275,
        maxWidth: 350,
        backgroundColor: '#fff',
      }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Loading data...
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </CardContent>
      </Card>
    </div>
  );
}
