import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { getProjects } from './store';

import Project from './routes/project';
import ProjectPage from './routes/projectPage';
import Projects from './routes/projects';
import LoadingCard from './components/loadingCard';

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/project')
  }, []);

  return <div />
}

export default function App() {
  const projects = useSelector(getProjects);
  const [showLoading, _] = useState(projects.length === 0);

  return (
    <BrowserRouter>
      {showLoading && <LoadingCard />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project" element={<Projects />} />
        <Route path="/project/:projectId/:pageIndex" element={<ProjectPage />} />
        <Route path="/project/:projectId" element={<Project />} />
      </Routes>
    </BrowserRouter>
  );
}
