import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { getProject } from '../store';

import '../styles/project.css';

export default function ProjectPage() {
  let { projectId, pageIndex } = useParams();
  pageIndex = +pageIndex;
  const navigate = useNavigate();
  const project = useSelector(getProject(projectId));

  if (!project) return <div />
  const page = project.pages[`${pageIndex}`];

  function Transcription({ transcription, page }) {
    const paragraphs = transcription.split('\n');
    return (
      <Paper
        elevation={0}
        sx={{
          padding: '1rem',
          marginBottom: '1rem'
        }}>
        <Typography variant="subtitle2">Page {page}</Typography>
        {paragraphs.map((p, idx) => (
          <Typography
            key={idx}
            style={{ margin: 0 }}
            variant="body1" component="p"
          >{p}</Typography>
        ))}
      </Paper>
    );
  }

  return (
    <div className="project-full">
      <div className="project-left">
        <IconButton
          color="primary"
          aria-label="go back"
          component="span"
          onClick={() => navigate(`/project/${projectId}`)}
          sx={{
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            color: '#fff'
          }}>
          <ArrowBackIcon />
        </IconButton>
        <div className="full-image" key={page}>
          <img src={`${page}half`} alt={pageIndex} />
        </div>
      </div>
      <div className="project-right">
        <Transcription transcription={project.transcriptions[+pageIndex - 1]} page={pageIndex} />
        <div className="split">
          {pageIndex > 1 ? (
            <Button
              variant="text"
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/project/${projectId}/${pageIndex - 1}`)}
            >
              Previous Page
            </Button>
          ) : <div />}
          {pageIndex < project.transcriptions.length ? (
            <Button
              variant="text"
              endIcon={<ArrowRightIcon />}
              onClick={() => navigate(`/project/${projectId}/${pageIndex + 1}`)}
            >
              Next Page
            </Button>
          ) : <div />}
        </div>
      </div>
    </div>
  )
}
