import { useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBNizKwOItu_ZvqA4cm3sDiZvttf5wAq-0',
  authDomain: 'freedmen-project.firebaseapp.com',
  databaseURL: 'https://freedmen-project-default-rtdb.firebaseio.com/',
  projectId: 'freedmen-project'
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export const signInWithGoogle = async () => {
  await setPersistence(auth, browserLocalPersistence);
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  console.log(result.user);
};

export function useAuth() {
  const [user, setUser] = useState(auth.currentUser);
  onAuthStateChanged(auth, () => setUser(auth.currentUser));
  return user;
};
