import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { getStateProjects } from '../store';

export default function StateProjects({ state }) {
  const navigate = useNavigate();
  const projects = useSelector(getStateProjects(state));

  if (!state) return <div />

  return (
    <div className="projects">
      {projects.map(project => (
        <div style={{ width: 345 }} key={project.id}>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => navigate(`/project/${project.id}`)}>
              <CardMedia
                component="img"
                height="200"
                image={`${project.pages['1']}thumbnail`}
                alt={project.title}
                sx={{ backgroundColor: '#000' }}
              />
              <CardContent>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {project.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ))}
    </div>
  );
}
