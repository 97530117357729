import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';

import { getStates } from '../store';
import StateProjects from './stateProjects';

import '../styles/projects.css';

const stateNames = {
  TN: 'Tennessee',
  NC: 'North Carolina',
  MS: 'Mississippi',
};

export default function Projetcs() {
  const states = useSelector(getStates);

  const [activeState, setActiveState] = useState(states[0]);

  useEffect(() => {
    if (activeState) return;
    selectState(states[0]);
  }, [states, activeState]);

  const selectState = state => {
    if (!state) return;
    setActiveState(state);
  }

  return (
    <div className="fullscreen">
      <div className="left">
        <div className="left-menu">
          <Paper sx={{ height: '100%', paddingTop: '1rem', width: '20rem' }} square elevation={5}>
            <MenuList>
              {states.map(state => (
                <MenuItem onClick={() => selectState(state)} key={state}>
                  {state === activeState && <ListItemIcon>
                    <Check />
                  </ListItemIcon>}
                  <ListItemText inset={state !== activeState}>{stateNames[state]}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </div>

        <StateProjects state={activeState} />
      </div>
    </div>
  )
}
