import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { db } from './core';

export const loadAnnotations = async (projectId) => {
  const q = query(collection(db, 'annotations'), where('projectId', '==', projectId));
  return getDocs(q);
}

export const listenForProject = (projectId, onUpdate) => {
  const q = query(collection(db, 'annotations'), where('projectId', '==', projectId));
  const unsubscribe = onSnapshot(q, onUpdate);
  return unsubscribe;
}
