import { useState, useRef, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Hotkeys from 'react-hot-keys';
import { writeBatch, doc } from 'firebase/firestore';
import { nanoid } from 'nanoid'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { getProject } from '../store';
import { db, signInWithGoogle, loadAnnotations, listenForProject, useAuth } from '../firebase';
import { stringToColor } from '../utils';

import '../styles/project.css';

const selectedColor = '#19b5fe';
const nameToColor = {};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export default function Project() {
  const [contracts, setContracts] = useState([]);
  const [activePage, _setActivePage] = useState();
  const [selected, setSelected] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const pagesContainerRef = useRef();
  const transcriptionsContainerRef = useRef();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const project = useSelector(getProject(projectId));
  const user = useAuth();

  const loadFromSnapshot = (snapshot) => {
    const contracts = snapshot.docs
      .filter(doc => doc.data().type === 'contract')
      .map(doc => ({ id: doc.id, ...doc.data() }));
    for (const contract of contracts) {
      if (!nameToColor[contract.author]) {
        nameToColor[contract.author] = stringToColor(contract.author);
      }
    }
    setContracts(contracts);
  }

  const initialize = async () => {
    const annotations = await loadAnnotations(projectId);
    loadFromSnapshot(annotations);
  }

  useEffect(() => {
    initialize();
    listenForProject(projectId, snapshot => {
      if (snapshot.metadata.hasPendingWrites) return;
      loadFromSnapshot(snapshot);
    });
  }, []);

  if (!project) return <div />;

  const setActivePage = (page, fromPage=true) => {
    _setActivePage(page);
    if (!page) return;
    if (!fromPage && pagesContainerRef) {
      const node = pagesContainerRef.current;
      node.children[page].scrollIntoViewIfNeeded();
    }
    if (fromPage && transcriptionsContainerRef) {
      const node = transcriptionsContainerRef.current;
      node.children[page].scrollIntoViewIfNeeded();
    }
    if (fromPage) {
      updateSelection(page);
    }
  }

  const startSelection = (page) => {
    if (isSelecting) return;
    setIsSelecting(true);
    setSelected([page, page]); // [from, to]
  }

  const updateSelection = (page) => {
    if (!isSelecting) return;
    setSelected([selected[0], page]);
  }

  const endSelection = () => {
    setIsSelecting(false);
  }

  const maxSelected = Math.max(...selected);
  const minSelected = Math.min(...selected);

  const addContract = async () => {
    if (!user || !user.email.endsWith('@law.stanford.edu')) return;
    if (selected.length === 0) return;
    const batch = writeBatch(db);

    for (const c of contracts) {
      if (c.to === maxSelected && c.from === minSelected) {
        batch.delete(doc(db, 'annotations', c.id));
        setContracts(contracts.filter(con => con !== c));
        setSelected([]);
        await batch.commit();
        return;
      }
    }

    const id = nanoid();
    const contract = {
      type: 'contract',
      name: `contract-${projectId}-${minSelected}`,
      from: minSelected,
      to: maxSelected,
      author: user.email,
      projectId,
      id,
    };
    batch.set(doc(db, 'annotations', id), contract);

    const newContracts = [contract];
    for (const c of contracts) {
      if (c.to < minSelected || c.from > maxSelected || c.author !== user.email) {
        newContracts.push(c);
      } else {
        batch.delete(doc(db, 'annotations', c.id));
      }
    }
    
    setContracts(newContracts);
    setSelected([]);
    await batch.commit();
  }

  function Transcription({ transcription, page }) {
    const paragraphs = transcription.split('\n');
    const isActive = +page === activePage;
    const isSelected = +page <= maxSelected && +page >= minSelected;

    return (
      <Paper
        elevation={0}
        sx={{
          padding: '.5rem 1rem',
          margin: '.5rem 0',
          cursor: 'pointer',
          outlineOffset: -4,
          outlineWidth: 4,
          outlineStyle: 'solid',
          outlineColor: isActive ? '#000' : 'transparent',
          backgroundColor: isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)',
          borderRadius: 0,
          boxShadow: isSelected ? `-4px 0 0 ${selectedColor}, -6px 0 0 ${selectedColor}` : 0,
        }}
        onClick={() => navigate(`/project/${projectId}/${page}`)}
        onMouseEnter={() => setActivePage(+page, false)}>
        <Typography variant="subtitle2">Page {page}</Typography>
        {paragraphs.map((p, idx) => (
          <Typography
            key={idx}
            style={{ margin: 0 }}
            variant="body1" component="p"
          >{p}</Typography>
        ))}
      </Paper>
    );
  }

  return (
    <div className="project-full">
      <div className="project-left" ref={pagesContainerRef}>
        <IconButton
          color="primary"
          aria-label="go back"
          component="span"
          onClick={() => navigate('/project')}
          sx={{
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            color: '#fff'
          }}>
          <ArrowBackIcon />
        </IconButton>
        {Object.keys(project.pages).map(page => {
          const url = project.pages[page];
          const isSelected = +page <= maxSelected && +page >= minSelected;
          const isLastRow = isSelected && +page + 4 > maxSelected;
          const isActive = +page === activePage;

          const _contracts = [];
          for (const contract of contracts) {
            if (contract.from <= +page && contract.to >= +page) {
              _contracts.push(contract);
            }
          }

          return (
            <div
              className="thumbnail"
              key={url}
              style={{
                outlineColor: isActive ? '#fff' : 'transparent',
                ...isSelected ? { backgroundColor: selectedColor } : {},
                ...+page === maxSelected ? { borderRightColor: 'transparent' } : {},
                ...isLastRow ? { borderBottomColor: 'transparent' } : {},
              }}
              onMouseEnter={() => setActivePage(+page)}
              onMouseDown={() => startSelection(+page)}
              onMouseUp={() => endSelection(+page)}
              onDoubleClick={() => navigate(`/project/${projectId}/${page}`)}
            >
              <NoMaxWidthTooltip
                enterDelay={2000}
                title={
                  <img 
                    src={`${url}small`}
                    style={{
                      height: '48vh',
                      width: 'calc(48vh * 2288 / 1748)'
                    }}
                    alt={page}
                  />}
                disableInteractive
                key={url}>
                <img src={`${url}thumbnail`} alt={page} draggable="false" />
              </NoMaxWidthTooltip>
              {_contracts.map(contract => (
                <Fragment key={contract.id}>
                  <div
                    className={`contract-bar ${contract.from === +page && 'first'} \
                                ${contract.to === +page && 'last'} \
                                ${user && user.email !== contract.author && 'other'}`}
                  />
                  {contract.from === +page && (
                    <Tooltip title={`Annotation by ${contract.author}`}>
                      <Avatar
                        sx={{ bgcolor: nameToColor[contract.author] }}
                        className={`contract-author ${user && user.email !== contract.author && 'other'}`}
                      >{contract.author[0].toUpperCase()}</Avatar>
                    </Tooltip>
                  )}
                  <Typography
                    variant="subtitle1"
                    className={`contract-page ${user && user.email !== contract.author && 'other'}`}
                  >{+page - contract.from + 1}</Typography>
                </Fragment>
              ))}
            </div>
          );
        })}

        <div className="actions">
          {user && (
            <Button
              variant="contained"
              color="primary"
              onMouseEnter={(e) => e.preventDefault()}
              onClick={addContract}>
              Mark As Contract [g]
            </Button>
          )}
          {!(user && user.email.endsWith('@law.stanford.edu')) && (<Button variant="contained" color="primary" onMouseEnter={(e) => e.preventDefault()} onClick={signInWithGoogle}>
            Sign In with your SLS Email to annotate
          </Button>)}
        </div>
      </div>
      <div className="project-right" ref={transcriptionsContainerRef} onClick={() => setSelected([])}>
        <Typography variant="h3" sx={{ marginBottom: '2rem' }}>{project.title}</Typography>
        {project.transcriptions.map((transcription, i) => (
          <Transcription key={i} transcription={transcription} page={i + 1} />
        ))}
      </div>

      <Hotkeys keyName="g" onKeyDown={addContract} />
    </div>
  )
}
