export const getProjects = store => store.projects || [];

export const getStates = store => {
  const states = new Set();
  const projects = getProjects(store);
  for (const project of projects) {
    states.add(project.state);
  }
  return [...states.values()];
}

export const getStateProjects = (state = '') => store => {
  const projects = getProjects(store);
  const s = state.toLowerCase();
  return projects.filter(p => p.state.toLowerCase() === s);
}

export const getProject = (projectId) => store => {
  const projects = getProjects(store);
  return projects.find(p => p.id === projectId);
}
