import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { projectReducer } from './reducers';

export * from './actions';
export * from './selectors';
export * from './reducers';

const initialState = {
  projects: [],
};

const reducer = combineReducers({
  projects: projectReducer,
});

// const persistConfig = { key: 'root', storage };
// const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(reducer, initialState, applyMiddleware(thunk));
export const persistor = persistStore(store);
